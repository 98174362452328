import React, { useEffect, useState } from 'react';

//MUI
import { Box, Typography, Grid } from '@mui/material';

//UTILS
import { RED, BLUE } from '/src/utils/Colors';

function ComingSoon() {
    const calculateTimeLeft = () => {
        const targetDate = new Date('2025-05-31T23:59:59');
        const now = new Date();
        const difference = targetDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                background: `linear-gradient(to right, ${RED}, ${BLUE})`,
                color: '#ffffff',
                textAlign: 'center',
            }}
        >

            <img src="/media/img/logo_final.svg" alt="AutoBroker Logo" style={{ width: '50%' }} />

            <Typography variant="h2" sx={{ mb: 3, color: 'white' }}>
                COMING SOON
            </Typography>

            <Grid container spacing={2} sx={{ my: 15, display: 'flex', justifyContent: 'center' }}>
                {['days', 'hours', 'minutes', 'seconds'].map((unit, index) => (
                    <Grid item xs={1} key={unit} sx={{ mb: 3, display: 'flex', justifyContent: 'space-around' }}>
                        <Box
                            sx={{
                                border: '2px solid #ffffff',
                                borderRadius: '50%',
                                padding: '20px',
                                width: '200px',
                                height: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h3" sx={{ color: 'white' }}>
                                {timeLeft[unit] !== undefined ? timeLeft[unit] : '00'}
                            </Typography>
                            <Typography variant="span" sx={{ color: '#B0B0B0' }}>
                                {unit.toUpperCase()}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Typography variant="h4" sx={{ maxWidth: '700px', color: 'white' }}>
                Exciting news! The first digital garage in Cyprus is launching soon. Get ready to easily list your vehicle, explore offerings from other users, and enjoy a seamless automotive experience!
            </Typography>
        </Box>
    );
}

export default ComingSoon;
