//UTILS
import { RED, BLUE } from '/src/utils/Colors';

function PhoneNumberInput ({ businessFromStorage, label, name, value, onChange, error }) {
    const handleInputChange = (e) => {
        // Remove non-numeric characters
        let numericValue = e.target.value.replace(/\D/g, '');

        // Limit the value to 8 characters
        if (numericValue.length > 8) {
            numericValue = numericValue.slice(0, 8);
        }

        // Call the onChange prop with the filtered value
        onChange({
            target: {
                name: e.target.name,
                value: numericValue,
            }
        });
    };

    return (
        <div className={businessFromStorage ? "col-md-12" : "col-md-6"} style={{ marginTop: '10px' }}>
            <div className="form-inner">
                <label>{label}<span style={{color: RED}}> *</span></label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ padding: '10px', height: '50px', fontSize: '13px', border: '1px solid #EEE', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}>+357</span>
                    <input
                        type="text"
                        name={name}
                        value={value}
                        onChange={handleInputChange}
                        style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', border: error ? `1px solid ${RED}` : '1px solid #EEE' }}
                    />
                </div>
            </div>
            <span style={{ color: RED, fontSize: '12px', fontWeight: '500' }}>{error}</span>
        </div>
    );
};

export default PhoneNumberInput;
