export const areasByCity = {
    Famagusta: [
        'Achna', 'Agia Napa', 'Agia Thekla', 'Agia Triada', 'Avgorou', 'Cape Greko', 
        'Deryneia', 'Frenaros', 'Kapparis', 'Konnos', 'Liopetri', 'Paralimni', 
        'Pernera', 'Profitis Elias', 'Protaras', 'Sotira', 'Vrysoulles'
    ],
    Nicosia: [
        'Nicosia - Ag. Antonios', 'Nicosia - Agios Andreas', 'Nicosia - Agios Ioannis', 
        'Nicosia - Ag Konst and Elenis', 'Nicosia - Agios Loukas', 'Nicosia - Agios Savvas', 
        'Nicosia - Agia Sofia', 'Nicosia - Chrysaliniotissa', 'Nicosia - Kaimakli', 
        'Nicosia - Lykabittos', 'Nicosia - Faneromeni', 'Nicosia - Omerie', 
        'Nicosia - Panagia', 'Palouriotissa', 'Nicosia - Trypiotis', 'Nicosia - Sopaz', 
        'Nicosia - Walled Old City', 'Agios Dometios - Agios Georgios', 'Agios Dometios - Agios Pavlos', 
        'Egkomi', 'Egkomi - Makedonitissa', 'Egkomi - Parissinos', 'Agia Eirini', 'Agia Marina Xyliatou', 
        'Agia Varvara Lefkosias', 'Agioi Iliofotoi', 'Agioi Omologites', 'Agioi Trimithias', 
        'Agios Epifanios Oreinis', 'Agios Georgios Kafkalou', 'Agios Ioannis Malountas', 
        'Agios Sozomenos', 'Agios Theodoros Soleas', 'Agios Vasileios', 'Aglantzia', 
        'Agrokipia', 'Akaki', 'Alampra', 'Alevga', 'Alona', 'Anageia', 'Analiontas', 'Arediou', 
        'Askas', 'Astromeritis', 'Beikioi', 'Carolina Park', 'Dali', 'Dali - Panag Evangelistria', 
        'Dali - Ag Konst and Elenis', 'Deftera Kato', 'Deftera Pano', 'Deneia', 'Episkopeio', 
        'Ergates', 'Evrychou', 'Farmakas', 'Fikardou', 'Flasou Kato', 'Flasou Pano', 'Fterikoudi', 
        'Galata', 'Gerakies', 'Geri', 'Gourri', 'GSP Area', 'Ilioupoli', 'Kakopetria', 'Kaliana', 
        'Kalithea', 'Kalo Chorio', 'Kalopanagiotis', 'Kampi', 'Kampia', 'Kampos', 'Kannavia', 
        'Kapedes', 'Kataliontas', 'Katydata', 'Klirou', 'Kokkinotrimithia', 'Korakou', 'Koutrafas Kato', 
        'Lagoudera', 'Lakatameia - Agios Nikolaos', 'Lakatameia - Anthoupolis', 'Lakatameia - Agios Mamas', 
        'Lakatameia - Agia Paraskevi', 'Latsia - Agios Georgios', 'Latsia - Archangelos Michail', 
        'Latsia - Agios Eleftherios', 'Lazanias', 'Linou', 'Livadia Lefkosias', 'Lympia', 
        'Lythrodontas', 'Malounta', 'Mammari', 'Mandres Lefkosias', 'Margi', 'Mathiatis', 'Meniko', 
        'Mitsero', 'Moni Kato', 'Mosfili', 'Moutoullas', 'Mylikouri', 'Nea Ledras', 'Neo Chorio Lefkosias', 
        'Nikitari', 'Nisou', 'Oikos', 'Orounta', 'Pachyammos', 'Palaichori Morfou', 'Palaichori Oreinis', 
        'Palaiometocho', 'Pedoulas', 'Pera', 'Pera Chorio', 'Peristerona Lefkosias', 'Peristeronari', 
        'Pigenia', 'Platanistasa', 'Politiko', 'Potami', 'Potamia', 'Psimolofou', 'Pyrgos Kato', 
        'Pyrgos Pano', 'Sia', 'Sinaoros', 'Soloi', 'Spilia - Agios Antonios', 'Stelmek Area', 
        'Strovolos - Agios Vasileios', 'Strovolos - Agios Dimitrios', 'Strovolos - Acropolis', 
        'Strovolos - Ap. Varnavas and Ag. Makar.', 'Strovolos - Archangelos', 'Strovolos - Dasoupolis', 
        'Strovolos - Enthomar Kyprianos', 'Strovolos - Stavros', 'Strovolos - Chryseleousa', 'Temvria', 
        'Trachoni Lefkosias', 'Tseri', 'Tymbou', 'Variseia', 'Voni'
    ],
    Limassol: [
        'Limassol - Agios Antonios', 'Limassol - Agia Triada', 'Limassol - Agios Georgios', 'Limassol - Agia Fyla', 
        'Limassol - Agios Ioannis', 'Limassol - Agia Napa', 'Limassol - Agios Nektarios', 'Limassol - Agios Nicolaos', 
        'Limassol - Agios Spyridon', 'Limassol - Agia Zoni', 'Limassol - Apostolos Andreas', 'Limassol - Arnaoutogeitonia', 
        'Ekali', 'Historical Center', 'Limassol - Kapsalos', 'Limassol - Katholiki', 'Limassol Marina', 
        'Limassol - Panag. Evangelistria', 'Limassol - Linopetra', 'Limassol - Mesa Geitonia', 'Limassol - Neapolis', 
        'Limassol - Omonia', 'Limassol - Petrou Kai Pavlou', 'Limassol - Tsirion', 'Limassol - Tsiflikoudia', 
        'Limassol - Tzami Tzentit', 'Limassol - Zakaki', 'Agia Paraskevi', 'Agios Amvrosios Lemesou', 'Agios Athanasios', 
        'Agios Dimitrios', 'Agios Georgios', 'Agios Georgios(Fragk)', 'Agios Ioannis', 'Agios Konstantinos', 
        'Agios Mamas', 'Agios Pavlos', 'Agios Stylianos', 'Agios Therapon', 'Agios Thomas', 'Agios Tychon', 
        'Agios Tychon Tourist Area', 'Agridia', 'Agros', 'Akapnou', 'Akrotiri', 'Akrounta', 'Alassa', 'Amiantos Kato', 
        'Anogyra', 'Apesia', 'Apostolos Loukas', 'Apsiou', 'Arakapas', 'Armenochori', 'Arsos Lemesou', 'Asgata', 
        'Asomatos Lemesou', 'Avdimou', 'Chalkoutsa', 'Chandria', 'Dierona', 'Dora', 'Doros', 'Dymes', 'Episkopi Lemesou', 
        'Eptagoneia', 'Erimi', 'Fasoula Lemesou', 'Fasouri', 'Foini', 'Foinikaria', 'Gerasa', 'Germasogeia', 
        'Germasogeia Tourist Area', 'Potamos Germasogeias', 'Kalo Chorio Lemesou', 'Kaminaria', 'Kantou', 'Kapileio', 
        'Kellaki', 'Kivides Kato', 'Kivides Pano', 'Koilani', 'Kolossi -Agios Loukas', 'Kolossi -Apostolos Andreas', 
        'Kontovathkeia', 'Korfi', 'Kouka', 'Kyperounta', 'Laneia', 'Lemithou', 'Limnatis', 'Lofou', 'Louvaras', 
        'Mandria Lemesou', 'Mathikoloni', 'Mesa Geitonia', 'Monagri', 'Monagroulli', 'Moni', 'Moni Agiou Georgiou Alamanou', 
        'Moniatis', 'Mouttagiaka', 'Mouttagiaka Tourist Area', 'Mylos Kato', 'Omodos', 'Pachna', 'Palodeia', 'Panthea', 
        'Paramali', 'Paramytha', 'Parekklisia', 'Parekklisia Tourist Area', 'Pelendri', 'Pentakomo', 'Pera Pedi', 
        'Pissouri', 'Platanisteia', 'Platres Kato', 'Platres Pano', 'Potamiou', 'Polemidia - Anthoupoli', 'Polemidia - Apostolos Varnavas', 
        'Polemidia - Archangelos Michail', 'Polemidia - Agios Nikolaos', 'Polemidia - Pan. Evangelistria', 'Polemidia - Makarios III', 
        'Polemidia Kato', 'Polemidia Pano', 'Potamitissa', 'Prastio Avdimou', 'Prastio Kellakiou', 'Prodromos', 'Profitis Ilias', 
        'Pyrgos Lemesou', 'Pyrgos Lemesou Tourist Area', 'Saittas', 'Sanatorio Kyperounyas', 'Sanida', 'Silikou', 
        'Sotira Lemesou', 'Souni', 'Spitali', 'Sterakonou', 'Sykopetra', 'Timiou Prodromou Mesa Geitonias', 'Trachoni Lemesou', 
        'Treis Elies', 'Trimiklini', 'Troodos', 'Tserkez Tsiftlik (Tserkezoi)', 'Vasa Kellakiou', 'Vasa Koilaniou', 'Vouni', 
        'Ypsonas', 'Zanakia', 'Zoopigi'
    ],
    Larnaca: [
        'Larnaka - Agioi Anargyroi I', 'Larnaka - Agioi Anargyroi II', 'Larnaka - Agios Nikolaos', 'Larnaka - Arch Makarios III', 
        'Larnaka - Chrysopolitissa', 'Larnaka - Kamares', 'Larnaka - Kokkines', 'Larnaka - Skala', 'Larnaka - Tsakilero', 
        'Larnaka - Finikoudes', 'Larnaka - Harbor', 'Larnaka - Makenzy', 'Larnaka - Sotiros', 'Agia Anna', 'Agioi Vavatsinias', 
        'Agios Theodoros', 'Alaminos', 'Alethriko', 'Anafotida', 'Anglisides', 'Aradippou - Apostolos Loukas', 
        'Aradippou - Agios Fanourios', 'Aradippou - Vlachos', 'Arsos Larnakas', 'Athienou', 'Avdellero', 'Choirokoitia', 
        'Dekeleia', 'Dromolaxia', 'Drosia', 'Drys Kato', 'Kalavasos', 'Kalo Chorio', 'Kellia', 'Kiti', 'Kivisili', 
        'Klavdia', 'Kofinou', 'Kornos', 'Lageia', 'Lefkara Kato', 'Lefkara Pano', 'Livadia Larnakas', 'Mari', 'Maroni', 
        'Mazotos', 'Melini', 'Meneou', 'Mosfiloti', 'Odou', 'Ora', 'Ormideia', 'Oroklini', 'Oroklini Tourist Area', 
        'Perivolia Larnakas', 'Petrofani', 'Psematismenos', 'Psevdas', 'Pyla', 'Pyla Tourist Area', 'Pyrga Larnakas', 
        'Skarinou', 'Softades', 'Stathmos Skarinou', 'Tersefanou', 'Tochni', 'Troulloi', 'Vavatsinia', 'Vavla', 
        'Xylofagou', 'Xylotymvou', 'Zygi'
    ],
    Paphos: [
        'Paphos - Agios Theodoros', 'Paphos - Agios Pavlos', 'Paphos - Anavargos', 'Paphos - Kato Paphos', 
        'Paphos - Moutallos', 'Tombs Of the Kings', 'Paphos - Universal', 'Paphos - Vasiliko', 'Acheleia', 
        'Agia Marina Chrysochous', 'Agia Marina Kelokedaron', 'Agia Marinouda', 'Agia Varvara', 'Agios Dimitrianos', 
        'Agios Georgios', 'Agios Nikolaos', 'Akourdaleia Pano', 'Akoursos', 'Amargeti', 'Anarita', 'Androlikou', 
        'Aphrodite hills Kouklia', 'Archimandrita Kato', 'Archimandrita Pano', 'Argaka', 'Arminou', 'Armou', 
        'Arodes Pano', 'Asprogia', 'Chlorakas', 'Choletria', 'Choli', 'Choulou', 'Chrysochou', 'Drouseia', 
        'Drymou', 'Empa', 'Episkopi Pafou', 'Filousa Kelokedaron', 'Fyti', 'Galataria', 'Geroskipou', 
        'Koloni', 'Geroskipou - Neapolis', 'Geroskipou Tourist Area', 'Giolou', 'Goudi', 'Ineia', 'Kallepeia', 
        'Kannaviou', 'Kathikas', 'Kedares', 'Kelokedara', 'Kissonerga', 'Koili', 'Konia', 'Kouklia Pafou', 
        'Kritou Marottou', 'Kritou Tera', 'Kynousa', 'Lasa', 'Latsi', 'Lemona', 'Lempa', 'Letymvou', 'Lysos', 
        'Makounta', 'Mamonia', 'Mandria Pafou', 'Marathounta', 'Maronas', 'Meladeia', 'Mesa Chorio', 
        'Mesana', 'Mesogi', 'Miliou', 'Mousere', 'Nata', 'Nea Dimmata', 'Neo Chorio Pafou', 'Panagia Pano', 
        'Pegeia', 'Pentalia', 'Peristerona Pafou', 'Polemi', 'Polis Chrysochous', 'Pomos', 'Praitori', 
        'Prastio Pafou', 'Prodromi', 'Psathi', 'Salamiou', 'Simou', 'Skoulli', 'Souskiou', 'Statos - Agios Fotios', 
        'Steni', 'Stroumpi Kato', 'Stroumpi Pano', 'Tala', 'Theletra', 'Thrinia', 'Timi', 'Trachypedoula', 
        'Tremithousa', 'Trimithousa', 'Tsada', 'Venus Rock Kouklia'
    ]
};