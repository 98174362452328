import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import BlogStandardPage from "./pages/blog-standard";
import About from "./pages/about";
import Contact from "./pages/contact";
import ReturnExchange from "./pages/return-ecchange";
import CustomerReview from "./pages/customer-review";
import Faq from "./pages/faq";
import BlogDetails from "./pages/blog-details";
import Shop from "/src/components/Shops/Shop";
import ProductDetails from "./pages/product-details";
import Checkout from "./pages/checkout";
import Compare from "./pages/compare";
import BrandCategory from "./pages/brand-category";
import SingleBrandCategory from "./pages/single-brand-category";
import CarDeatils from "./pages/car-deatils";
import CarAuctionDetails from "./pages/car-auction-details";
import CarListingLeftSidebar from "./pages/car-listing-left-sidebar";
import CarListingRightSidebar from "./pages/car-listing-right-sidebar";
import CarListingNoSidebarPage from "./pages/car-listing-no-sidebar";
import SpecialOffer from "./pages/special-offer";
import "../src/assets/css/bootstrap-icons.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/boxicons.min.css";
import "../src/assets/css/fontawesome.min.css";
import "../src/assets/css/nice-select.css";
import "../src/assets/css/swiper-bundle.min.css";
import "../src/assets/css/style.css";
import FileNotFound from "./pages/FileNotFound";
import PathNavigationWatcher from "./utils/PathNavigationWatcher";

import Layout from '/src/layout/Layout';
import CarsPage from './pages/Vehicles/CarsPage';
import BikesPage from './pages/Vehicles/BikesPage';
import VansPage from './pages/Vehicles/VansPage';
import TrucksAndTrailersPage from './pages/Vehicles/TrucksAndTrailersPage';
import BicyclesPage from './pages/Vehicles/BicyclesPage';
import BoatsPage from './pages/Vehicles/BoatsPage';
import FarmAndMachineryPage from './pages/Vehicles/FarmAndMachineryPage';

//Account Pages:
// Import Account components
import AccountPage from '/src/pages/AccountPage';
import Dashboard from '/src/components/AccountPage/Dashboard';
import Inbox from '/src/components/AccountPage/Inbox';
import MyGarage from '/src/components/AccountPage/MyGarage';
import MyListings from '/src/components/AccountPage/MyListings';
import Favourites from '/src/components/AccountPage/Favourites';
import Transactions from '/src/components/AccountPage/Transactions';
import AccountSettings from '/src/components/AccountPage/AccountSettings';
import Preferences from '/src/components/AccountPage/Preferences';
import RequestDeleteAccount from '/src/components/AccountPage/RequestDeleteAccount';

//Shops
import ShopsPage from '/src/pages/ShopsPage';

//Payments
import ShoppingCartPage from '/src/pages/ShoppingCartPage';
import PaymentPage from '/src/pages/PaymentPage';

import LoizosTest from './pages/LoizosTest';

//Google OAth
import { AuthProvider } from '/src/context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

//STRIPE
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51QG3YFD7MSDfdjlWVzBDSVTXdkNtfKLfngxOwYQcm09a6DMI3cjGXwSJ5GB9mXrN9H0g2XLWLtCHjxyJ0YYyGK1S00XTLXytfA');


//MUI theme -> Force Font Family
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

function App() {

  // STRIPE SETUP
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Fetch clientSecret from the backend on page load
    fetch('/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount: 1000, currency: 'usd' }), // Example payload
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => console.error('Error fetching client secret:', error));
  }, []);

  const options = {
    clientSecret,
  };

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId="1007067420704-4qf1k2liid0h7nqig8ltncutc3nou55o.apps.googleusercontent.com">
        <AuthProvider>
          <BrowserRouter>
            <PathNavigationWatcher />
            <Layout>
              <Routes>
                <Route path="/" index element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/return-enchange" element={<ReturnExchange />} />
                <Route path="/customer-review" element={<CustomerReview />} />
                <Route path="/blog-standard" element={<BlogStandardPage />} />
                <Route path="/blog-details" element={<BlogDetails />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/product-details" element={<ProductDetails />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/compare" element={<Compare />} />
                <Route path="/brand-category" element={<BrandCategory />} />
                <Route path="/single-brand-category" element={<SingleBrandCategory />} />
                <Route path="/car-deatils" element={<CarDeatils />} />
                <Route path="/car-auction-details" element={<CarAuctionDetails />} />
                <Route path="/car-listing-left-sidebar" element={<CarListingLeftSidebar />} />
                <Route path="/car-listing-right-sidebar" element={<CarListingRightSidebar />} />
                <Route path="/car-listing-no-sidebar" element={<CarListingNoSidebarPage />} />
                <Route path="/special-offer" element={<SpecialOffer />} />
                <Route path="/faq" element={<Faq />} />

                <Route path="/cars" element={<CarsPage />} />
                <Route path="/bikes" element={<BikesPage />} />
                <Route path="/vans" element={<VansPage />} />
                <Route path="/trucks-and-trailers" element={<TrucksAndTrailersPage />} />
                <Route path="/bicycles" element={<BicyclesPage />} />
                <Route path="/boats" element={<BoatsPage />} />
                <Route path="/farm-and-machinery" element={<FarmAndMachineryPage />} />

                <Route path="/cart" element={<ShoppingCartPage />} />
                {/* Elements is used for stripe functionality */}
                <Route path="/payment" element={
                  clientSecret ? (
                    <Elements stripe={stripePromise} options={options}>
                      <PaymentPage />
                    </Elements>
                  ) : (
                    <div>Loading payment details...</div>
                  )
                } />

                {/* Account Pages */}
                <Route path="/account/*" element={<AccountPage />}>

                  {/* Redirect to dashboard as the default page for /account/ */}
                  <Route path="*" element={<Navigate to="/account/dashboard" replace />} />

                  {/* Simple Pages */}
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="garage" element={<MyGarage />} />
                  <Route path="listings" element={<MyListings />} />
                  <Route path="favourites" element={<Favourites />} />
                  <Route path="credits" element={<Transactions />} />
                  <Route path="delete-account" element={<RequestDeleteAccount />} />

                  {/* Nested Pages of Inbox*/}
                  <Route path="inbox/*" element={<Inbox />}>
                    <Route path="" element={<Navigate to="messages" replace />} />
                    <Route path="messages" element={<Inbox />} />
                    <Route path="notifications" element={<Inbox />} />
                  </Route>

                  {/* Nested Pages of Settings*/}
                  <Route path="settings/*" element={<AccountSettings />}>
                    <Route path="" element={<Navigate to="details" replace />} />
                    <Route path="details" element={<AccountSettings />} />
                    <Route path="password" element={<AccountSettings />} />
                  </Route>

                  {/* Nested Pages of Preferences*/}
                  <Route path="preferences/*" element={<Preferences />}>
                    <Route path="" element={<Navigate to="notifications" replace />} />
                    <Route path="notifications" element={<Preferences />} />
                    <Route path="emails" element={<Preferences />} />
                    <Route path="site" element={<Preferences />} />
                  </Route>

                </Route>

                {/* Shops */}
                <Route path="shops" element={<ShopsPage />} />
                {/* Dynamic Shop Route */}
                <Route path="shops/:shopName" element={<Shop />} />


                {/* NOT FOUND */}
                <Route path="*" element={<FileNotFound />} />

              </Routes>
            </Layout>
          </BrowserRouter>
        </AuthProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;