import React, { useState, forwardRef, useImperativeHandle } from "react";

//MUI
import { SvgIcon } from "@mui/material";

//MUI Icons
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const ViberIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M444 49.9C431.3 38.2 379.9 .9 265.3 .4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9 .4-85.7 .4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9 .4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9 .6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4 .7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5 .9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9 .1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7 .5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1 .8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z" /></svg>    </SvgIcon>
);

//UTILS
import { areasByCity } from "/src/utils/Areas";
import { validateField } from "/src/utils/ValidateField";

//Child Components
import TextInput from '/src/components/AccountPage/childComponents/TextInput';
import PhoneNumberInput from '/src/components/AccountPage/childComponents/PhoneNumberInput';
import UrlInput from '/src/components/AccountPage/childComponents/UrlInput';
import SelectInput from '/src/components/AccountPage/childComponents/SelectInput';

const FormPersonal = forwardRef(({ userData, formUserData, setDataStates, formErrors, setError }, ref) => {


    //User ref to validate form from parrent
    useImperativeHandle(ref, () => ({
        validateForm
    }));

    //Get Areas per city
    const areas = formUserData.city ? areasByCity[formUserData.city] || [] : [];

    //States to hold drop down selected values
    const [isCityHovered, setIsCityHovered] = useState(false);
    const [isAreaHovered, setIsAreaHovered] = useState(false);



    const validateForm = async () => {

        const isFirstNameValid = validateField("firstName", formUserData.firstName, (error) => setError('firstNameError', error));
        const isLastNameValid = validateField("lastName", formUserData.lastName, (error) => setError('lastNameError', error));
        const isEmailValid = validateField("email", formUserData.email, (error) => setError('emailError', error));
        const isMobileValid = validateField("mobile", formUserData.mobile, (error) => setError('mobileError', error));
        //Optional Fields
        const isAddressValid = true;
        const isCityValid = true;
        const isAreaValid = true;
        const isPostcodeValid = true;
        const isWhatsappValid = true;
        const isViberValid = true;

        if (!isFirstNameValid || !isLastNameValid || !isEmailValid || !isMobileValid ||
            !isAddressValid || !isCityValid || !isAreaValid || !isPostcodeValid || !isWhatsappValid || !isViberValid) {
            return { isMobileNew: null, isEmailNew: null, isFormValid: false };
        }

        //Check if mobile and email are new values (if yes we need to send otp)
        const isMobileNew = formUserData.mobile !== userData?.mobile;
        const isEmailNew = formUserData.email !== userData?.email;
        setError("submitError", "");
        return { isMobileNew, isEmailNew, isFormValid: true };
    }

    return (
        <form>
            <div className="account-settings-form">
                <div className="row g-4" style={{ marginTop: '5px' }}>

                    {/* First Name */}
                    <TextInput
                        businessFromStorage={userData?.type === "Personal"}
                        label="First Name"
                        name="firstName"
                        placeholder="Jerry"
                        value={formUserData.firstName}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError('firstNameError', "");
                            setDataStates("firstName", e.target.value);
                        }}
                        error={formErrors.firstNameError}
                    />

                    {/* Last Name */}
                    <TextInput
                        businessFromStorage={userData?.type === "Personal"}
                        label="Last Name"
                        name="lastName"
                        placeholder="Smith"
                        value={formUserData.lastName}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError('lastNameError', "");
                            setDataStates("lastName", e.target.value);
                        }}
                        error={formErrors.lastNameError}
                    />

                    {/* Email Address */}
                    <TextInput
                        businessFromStorage={userData?.type === "Personal"}
                        label="Email Address"
                        name="email"
                        placeholder="jerrysmith@autobroker.cy"
                        value={formUserData.email}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError('emailError', "");
                            setDataStates("email", e.target.value);
                        }}
                        error={formErrors.emailError}
                    />

                    {/* Phone Number */}
                    <PhoneNumberInput
                        businessFromStorage={userData?.type === "Personal"}
                        label="Phone Number"
                        name="mobile"
                        value={formUserData.mobile}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError('mobileError', "");
                            setDataStates("mobile", e.target.value);
                        }}
                        error={formErrors.mobileError}
                    />

                    {/* Personal Address */}
                    <TextInput
                        optional={true}
                        businessFromStorage={userData?.type === "Personal"}
                        label="Address"
                        name="address"
                        placeholder="Kyprianou, 47"
                        value={formUserData.address}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError("addressError", "");
                            setDataStates("address", e.target.value);
                        }}
                        error={formErrors.addressError}
                    />


                    {/* Personal City */}
                    <SelectInput
                        optional={true}
                        label="City"
                        name="city"
                        value={formUserData.city}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError("cityError", "");
                            setDataStates("city", e.target.value);
                            setDataStates("area", ''); // Reset area when city changes
                        }}
                        error={formErrors.cityError}
                        options={[
                            { value: "Nicosia", label: "Nicosia" },
                            { value: "Limassol", label: "Limassol" },
                            { value: "Larnaca", label: "Larnaca" },
                            { value: "Famagusta", label: "Famagusta" },
                            { value: "Paphos", label: "Paphos" },
                        ]}
                        onMouseEnter={() => setIsCityHovered(true)}
                        onMouseLeave={() => setIsCityHovered(false)}
                        hovered={isCityHovered}
                    />

                    {/* Personal Area */}
                    <SelectInput
                        optional={true}
                        label="Area"
                        name="area"
                        value={formUserData.area}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError("areaError", "");
                            setDataStates("area", e.target.value);
                        }}
                        error={formErrors.areaError}
                        options={areas.map(areaOption => ({
                            value: areaOption,
                            label: areaOption,
                        }))}
                        onMouseEnter={() => setIsAreaHovered(true)}
                        onMouseLeave={() => setIsAreaHovered(false)}
                        hovered={isAreaHovered}
                    />

                    {/* Personal PostCode */}
                    <TextInput
                        optional={true}
                        businessFromStorage={userData?.type === "Personal"}
                        label="Postcode"
                        name="postcode"
                        placeholder="7155"
                        value={formUserData.postcode}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError("postcodeError", "");
                            setDataStates("postcode", e.target.value);
                        }}
                        error={formErrors.postcodeError}
                    />



                    <UrlInput
                        label="Whatsapp"
                        name="whatsapp"
                        value={formUserData.whatsapp}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError("whatsappError", "");
                            setDataStates("whatsapp", e.target.value);
                        }}
                        error={formErrors.whatsappError}
                        icon={WhatsAppIcon}
                        color="#25D366"
                    />

                    <UrlInput
                        label="Viber"
                        name="viber"
                        value={formUserData.viber}
                        onChange={(e) => {
                            setError("submitError", "");
                            setError("viberError", "");
                            setDataStates("viber", e.target.value);
                        }}
                        error={formErrors.viberError}
                        icon={ViberIcon}
                        color="#7360f2"
                    />
                </div>
            </div>
        </form>
    );
});

export default FormPersonal;

