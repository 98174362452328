//Helper Function to validate Fields
export const validateField = (field, value, setError) => {

    //First Name
    if (field === "firstName") {
        if (!value || value === undefined || value === '') {
            setError("The First Name cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Last Name
    if (field === "lastName") {
        if (!value || value === undefined || value === '') {
            setError("The Last Name cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Email Address
    if (field === "email") {
        if (!value || value === undefined || value === '') {
            setError("The Email cannot be empty!");
            return false;
        } else {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                setError("Invalid email format!");
                return false;
            }
            setError("");
            return true;
        }
    }

    //Phone Number
    if (field === "mobile") {
        if (!value || value === undefined || value === '') {
            setError("The Phone Number cannot be empty!");
            return false;
        } else {

            const phoneNumberRegex = /^9\d{7}$/;
            if (!phoneNumberRegex.test(value)) {
                setError("Only Cypriot mobile phone numbers are allowed (9xxxxxxx)");
                return false;
            }
            setError("");
            return true;
        }
    }

    // Password
    if (field === "password" || field === "newPassword") {
        if (!value || value === undefined || value === '') {
            if(field === "password"){
                setError("The Password cannot be empty!");
            }else if(field === "newPassword")
                setError("The New Password cannot be empty!");
            return false;
        } else {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumbers = /\d/.test(value);
            const hasSpecialChar = /[!@#$%^&*]/.test(value);
            const hasValidLength = value.length >= 8 && value.length <= 20;
            if (!(hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar && hasValidLength)) {
                setError(
                    <>
                        Your password should: <br />
                        - contain 8-20 characters <br />
                        - contain lower case characters [a-z] <br />
                        - contain upper case characters [A-Z] <br />
                        - contain numbers [0-9] <br />
                        - contain one of the following symbols !@#$%^&* <br />
                    </>
                );
                return false;
            }
            setError("");
            return true;
        }
    }

    // Confirm Password
    if (field === "confirmPassword") {
        if (!value || value === undefined || value === '') {
            setError("The Confirm Password cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Business Name
    if (field === "businessName") {
        if (!value || value === undefined || value === '') {
            setError("The Business Name cannot be empty!");
            return false;
        } else {
            if(value.includes("_")){
                setError("The Business Name cannot include special characters like underscores (_). Please remove any special characters.");
                return false;
            }
            setError("");
            return true;
        }
    }

    //Business Name
    if (field === "businessType") {
        if (!value || value === undefined || value === '') {
            setError("The Business Type cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Business Address
    if (field === "address") {
        if (!value || value === undefined || value === '') {
            setError("The Address cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Business City
    if (field === "city") {
        if (!value || value === undefined || value === '') {
            setError("The City cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Business Area
    if (field === "area") {
        if (!value || value === undefined || value === '') {
            setError("The Area cannot be empty. Please first select a city!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Business Postcode
    if (field === "postcode") {
        if (!value || value === undefined || value === '') {
            setError("The Postcode cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Business VAT No
    if (field === "vatno") {
        if (!value || value === undefined || value === '') {
            setError("The VAT Number cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }
    }

    //Business Website
    if (field === "website") {
        if (value) {
            const websiteRegex = /^https:\/\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]+$/;
            if (!websiteRegex.test(value)) {
                setError("The website URL must start with 'https://' and be a valid URL.");
                return false;
            }
            setError("");
            return true;
        } else {
            return true;
        }
    }

    //Business Facebook
    if (field === "facebook") {
        if (value) {
            const facebookRegex = /^https:\/\/(www\.|m\.)?facebook\.com\/[a-zA-Z0-9(\.\?)?]+\/?$/;
            if (!facebookRegex.test(value)) {
                setError("The Facebook URL must start with 'https://facebook.com/' or 'https://www.facebook.com/'.");
                return false;
            }
            setError("");
            return true;
        } else {
            return true;
        }
    }

    //Validates if password is empty (used only on login page)
    if(field === "passwordLogin"){ 
        if (!value || value === undefined || value === '') {
            setError("The Password cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }

    }

    //Validates if Current password is empty (used only on Account Settings > Change Password)
    if(field === "currentPassword"){ 
        if (!value || value === undefined || value === '') {
            setError("The Current Password cannot be empty!");
            return false;
        } else {
            setError("");
            return true;
        }

    }
};