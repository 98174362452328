//MUI
import { Box } from '@mui/material';

//UTILS
import { RED, BLUE, BLACK_1 } from '/src/utils/Colors';

function UrlInput ({ label, name, value, onChange, error, icon: Icon, color }) {
    return (
        <div className="col-md-6" style={{ marginTop: '10px' }}>
            <div className="form-inner">
                <label>{label}<span style={{color: BLACK_1}}> (Optional)</span></label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ padding: '10px', height: '50px', display: 'flex', alignItems: 'center', border: '1px solid #EEE', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}>
                        <Icon sx={{ fontSize: '20px', color: color }} />
                    </Box>
                    <input
                        type="text"
                        name={name}
                        value={value}
                        onChange={onChange}
                        style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', border: error ? `1px solid ${RED}` : '1px solid #EEE', flex: 1 }}
                    />
                </div>
            </div>
            <span style={{ color: RED, fontSize: '12px', fontWeight: '500' }}>{error}</span>
        </div>
    );
};

export default UrlInput;