//UTILS
import { RED, BLUE, BLACK_1 } from '/src/utils/Colors';

function SelectInput({ label, name, value, onChange, error, options, onMouseEnter, onMouseLeave, hovered, optional }) {
    return (
        <div className="col-md-6" style={{ marginTop: '10px' }}>
            <div className="form-inner">
                <label>{label}{optional ? <span style={{color: BLACK_1}}> (Optional)</span> : <span style={{color: RED}}> *</span>}</label>
                <select
                    name={name}
                    value={value}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onChange={onChange}
                    style={{
                        height: '50px',
                        background: '#F2F3F5',
                        border: hovered ? `1px solid ${BLUE}` : error ? `1px solid ${RED}` : '0',
                    }}
                >
                    <option value="">Select {label}</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <span style={{ color: RED, fontSize: '12px', fontWeight: '500' }}>{error}</span>
        </div>
    );
};

export default SelectInput;