import axios from 'axios';

export const getApiBaseUrl = () => {
    if (window.location.origin.includes('localhost')) {
        return 'http://autobroker.cy:9080';
    } else {
        return window.location.origin;
    }
};

const apiBaseUrl = getApiBaseUrl();
export const readAPI = `${apiBaseUrl}/$TableRead`;
export const eraseAPI = `${apiBaseUrl}/$TableErase`;
export const translateAPI = `${apiBaseUrl}/$TableTranslate`;
export const otpApi = `${apiBaseUrl}/$TableOtp`;
export const verifyAPI = `${apiBaseUrl}/$TableVerifyOtp`;
export const loginAPI = `${apiBaseUrl}/$AutoLogin`;
export const maintenanceAPI = `${apiBaseUrl}/$TableMaintenance`
export const getViewAPI = `${apiBaseUrl}/$TableGetView`;
export const modifyAPI = `${apiBaseUrl}/$TableModify`;
export const mailAPI = `${apiBaseUrl}/Mail`;
export const attachAPI = `${apiBaseUrl}/$TableAttach`;
export const removeAttachmentAPI = `${apiBaseUrl}/$TableRemoveAttachment`;
export const imageAPI = `${apiBaseUrl}/$TableImage`;

//--------------------------------------------------------------------------------------
//---------------------------------- MAINTENANCE APIs ----------------------------------
//--------------------------------------------------------------------------------------
export const getMaintenanceMode = async () => {
    const url = `${maintenanceAPI}?get=true`;

    try {
        const response = await axios.get(url);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.response?.data?.message || error.message };
    }
};

export const setMaintenanceMode = async (flag) => {
    try {

        //Get Maintenance Before Change
        const maintenanceModeBefore = await getMaintenanceMode();

        //Change Maintenance Mode
        const response = await axios.get(`${apiBaseUrl}/$TableMaintenance?set=${flag}`);
        if (response.status === 200) {
            console.warn(`Maintenance Mode changed from ${maintenanceModeBefore.data} to ${flag}`);
        }

        //Double Check Maintenance Mode
        const maintenanceModeAfter = await getMaintenanceMode();
        window.location.href = '/';
        return { success: true, data: maintenanceModeAfter.data };

    } catch (error) {
        return { success: false, error: error.response?.data?.message || error.message };
    }
};
//--------------------------------------------------------------------------------------
//------------------------------ END OF MAINTENANCE APIs -------------------------------

//Helper method to encode API Query String
export const objectToQueryString = (obj) => {
    const queryString = Object.keys(obj).map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    }).join('&');
    return queryString;
};

//A helper function to get current language selected (used in translate APIs)
export const getLanguage = () => {
    const language = 'en';

    // Mapping languages 
    const languageToCodepage = {
        'el': '1253', // Greek
        'rus': '1251'  // Russian
    };

    // Get the codepage for the current language, or use the default
    const codepage = languageToCodepage[language] || '1252';
    return codepage;
}

//A function to translate an array of text
// $TableTranslate 
export const translateArray = async (array) => {
    //Get selected language, If English, return the original text
    const codepage = getLanguage();
    if (codepage === '1252') {
        return array;
    }

    // API
    const api = `${translateAPI}?string=${encodeURIComponent(array.join("\t"))}&codepage=${codepage}`;
    try {
        const response = await axios.get(api);
        if (response.data && Array.isArray(response.data)) {
            return response.data;
        } else {
            console.error('Translation API response format is unexpected:', response.data);
            return textToTranslate;
        }
    } catch (error) {
        console.error('Error calling translation API:', error);
        return textToTranslate;
    }
};

//A function to generate an OTP code with expiry
export const generateOtpWithExpiry = () => {
    const otp = Math.floor(100000 + Math.random() * 900000).toString();
    const expiry = new Date().getTime() + 15 * 60 * 1000; // Current Time + 5 mins
    return { otp, expiry };
};

export const attachImage = async () => {
    const img_src = '/media/img/home2/icon/audi.svg';

    // Fetch the image as a blob
    const response = await fetch(img_src);
    const blob = await response.blob();
    console.log(blob);

    const file = new File([blob], 'audi.svg', { type: 'image/svg+xml' });

    console.log(file);

    const formData = new FormData();
    formData.append('system', 'auto');
    formData.append('file', 'contactus');
    formData.append('record', parseInt(6, 10));  // Example record ID
    formData.append('file', file);  // Append the file object, not the URL

    try {
        const response = await axios.post(attachAPI, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log(response);

        return { success: true, data: response.data };

    } catch (error) {
        return { success: false, error: error.message };
    }
};

//A function to Attach Image to a record
//$TableRead
export const attachAvatar = async (formData) => {
    try {
        const response = await axios.post(attachAPI, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error };
    }
};

//A function to remove attachment
//$TableRemoveAttachment
export const removeAttachment = async (userRecord, attachmentToRemove) => {
    try {
        const queryParams = {
            file: 'webuser',
            system: 'auto',
            record: parseInt(userRecord, 10),
            attachment: attachmentToRemove
        };
        
        const response = await axios.get(`${removeAttachmentAPI}?${objectToQueryString(queryParams)}`);
        if (response.data !== 'ok') {
            throw new Error("Failed to remove attachment");
        }

        return ({ succes: true, data: response.data });
    } catch (error) {
        return ({ success: false, error: error });
    }
}

//A function to get priceplans
//$TableGetView
export const getPricePlans = async () => {

    const queryParams = {
        file: 'pricing',
        system: 'auto',
        report: 'price_plans',
        compact: 'true'
    };

    try {
        const response = await axios.get(`${getViewAPI}?${objectToQueryString(queryParams)}`);
        if (!response.data) {
            throw new Error("Failed to get Pricing Plans");
        }

        return ({ succes: true, data: response.data });

    } catch (error) {
        return ({ succes: false, error: error });
    }
};

export const getAttachmentForUser = async (email) => {

    const queryParams = {
        file: 'webuser',
        system: 'auto',
        report: 'user_attachments',
        compact: 'true',
        secondary: `email\n${email}\n${email}`
    };

    try {
        const response = await axios.get(`${getViewAPI}?${objectToQueryString(queryParams)}`);
        if (!response.data) {
            throw new Error("Failed to get User Attachments");
        }

        return ({ succes: true, data: response.data });

    } catch (error) {
        return ({ succes: false, error: error });
    }
};