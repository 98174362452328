//UTILS
import { RED, BLUE, BLACK_1 } from '/src/utils/Colors';

function TextInput ({ businessFromStorage, label, name, placeholder, value, onChange, error, optional }) {
    return (
        <div className={businessFromStorage ? "col-md-12" : "col-md-6"} style={{ marginTop: '10px' }}>
            <div className="form-inner">
                <label>{label}{optional ? <span style={{color: BLACK_1}}> (Optional)</span> : <span style={{color: RED}}> *</span>}</label>
                <input
                    type="text"
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    style={{ border: error ? `1px solid ${RED}` : '1px solid #EEE' }}
                />
            </div>
            <span style={{ color: RED, fontSize: '12px', fontWeight: '500' }}>{error}</span>
        </div>
    );
};

export default TextInput;