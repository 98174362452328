export const BLUE = "#1D4389";
export const RED = "#E42526";
export const BLACK = "#080E1E";
export const GRAY = "#AAA9AA";
export const GREEN = "#04BF50";

export const RED_1 = "#E95151";
export const RED_2 = "#EF7C7D";
export const RED_3 = "#F4A8A8";
export const RED_4 = "#FAD3D4";
export const RED_5 = "#FCE9E9";
export const RED_6 = "#FEF4F4";

export const BLUE_1 = "#4A69A1";
export const BLUE_2 = "#778EB8";
export const BLUE_3 = "#A5B4D0";
export const BLUE_4 = "#D2D9E7";
export const BLUE_5 = "#E8ECF3";
export const BLUE_6 = "#F4F6F9";

export const BLACK_1 = "#393E4B";
export const BLACK_2 = "#6B6E78";
export const BLACK_3 = "#9C9FA5";
export const BLACK_4 = "#CECFD2";
export const BLACK_5 = "#E6E7E8";
export const BLACK_6 = "#F3F3F4";

export const GRAY_1 = "#BBBABB";
export const GRAY_2 = "#CCCBCC";
export const GRAY_3 = "#DDDDDD";
export const GRAY_4 = "#EEEEEE";
export const GRAY_5 = "#F7F6F7";
export const GRAY_6 = "#FBFBFB";

export const GREEN_1 = "#36CC73";
export const GREEN_2 = "#68D996";
export const GREEN_3 = "#9BE5B9";
export const GREEN_4 = "#CDF2DC";
export const GREEN_5 = "#E6F9EE";
export const GREEN_6 = "#F2FCF6";