import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    ``
    //State to check if user is logged in
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        return !!(localStorage.getItem('userData') || sessionStorage.getItem('userData'));
    });

    //State to set user data in storage
    const [userData, setUserData] = useState(() => {
        const storedUserData = localStorage.getItem('userData') || sessionStorage.getItem('userData');
        return storedUserData ? JSON.parse(storedUserData) : {};
    });

    //Helper function to clear all states
    const clearUserData = () => {
        setUserData({});
        setIsLoggedIn(false);
    };

    //Checks if user is logged in
    useEffect(() => {
        if (userData && userData.userRecord) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [userData]);

    //Helper function to login the user
    const loginUser = (rememberMe, name, surname, email, userRecord, businessType, googleToken,
        language, currency, odometer
    ) => {

        //Prepare Site Preferences

        const newUserData = {
            userRecord,
            firstName: name,
            lastName: surname,
            email,
            businessType,
            sitePreferences: {
                language,
                currency,
                odometer
            }
        };

        // Conditionally add googleToken if they are not null
        if (googleToken) { newUserData.googleToken = googleToken; }

        if (rememberMe) {
            localStorage.setItem('userData', JSON.stringify(newUserData));
        } else {
            sessionStorage.setItem('userData', JSON.stringify(newUserData));
        }

        setUserData(newUserData);
        setIsLoggedIn(true);

        const audio = new Audio("/media/audio/bubble.mp3");
        audio.play();
    };

    //Helper Function to logout the user
    const logoutUser = () => {
        localStorage.removeItem('userData');
        sessionStorage.removeItem('userData');
        clearUserData();
        window.location.href = '/';
    };

    //Helper function to revoke access to Google account
    const revokeGoogleAccess = async (googleToken) => {
        fetch(`https://accounts.google.com/o/oauth2/revoke?token=${googleToken}`, {
            method: 'POST',
            headers: { 'Content-type': 'application/x-www-form-urlencoded' }
        })
            .then(() => {
                localStorage.removeItem('userData');
                sessionStorage.removeItem('userData');
            })
            .catch((error) => {
                throw new Error("Failed to Revoke Google Account!");
            });
    };

    return (
        <AuthContext.Provider value={{ userData, isLoggedIn, loginUser, logoutUser, revokeGoogleAccess }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook for accessing AuthContext
export const useAuth = () => React.useContext(AuthContext);
